import { createApp } from 'vue'
import { createPinia } from 'pinia'
// import App from './App.vue'
import { defineAsyncComponent } from 'vue'

// Graphql
import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'

const cache = new InMemoryCache()

const apolloClient = new ApolloClient({
  cache,
  uri: '/api',
})

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})

const VideoPlayer = defineAsyncComponent(() =>
  import('./components/VideoPlayer.vue')
)
const SubscriptionChannels = defineAsyncComponent(() =>
  import('./components/Subscription/Channels.vue')
)
const SubscriptionCancel = defineAsyncComponent(() =>
  import('./components/Subscription/Cancel.vue')
)
const Accordion = defineAsyncComponent(() =>
  import('./components/Global/Accordion.vue')
)
const Questionnaire = defineAsyncComponent(() =>
  import('./components/FindMyLevel/Questionnaire.vue')
)
const HelpAdviceHero = defineAsyncComponent(() =>
  import('./components/HelpAdvice/Hero.vue')
)
const HelpAdviceFaqs = defineAsyncComponent(() =>
  import('./components/HelpAdvice/Faqs.vue')
)
const HelpAdviceVideos = defineAsyncComponent(() =>
  import('./components/HelpAdvice/Videos.vue')
)
const VideoSearch = defineAsyncComponent(() =>
  import('./components/Videos/Search.vue')
)
const VideoLikes = defineAsyncComponent(() =>
  import('./components/Videos/Likes.vue')
)
const HeaderMenuToggle = defineAsyncComponent(() =>
  import('./components/HeaderMenuToggle.vue')
)
const VideoModal = defineAsyncComponent(() =>
  import('./components/VideoModal.vue')
)
const VideoGuidesModal = defineAsyncComponent(() =>
  import('./components/VideoGuidesModal.vue')
)

const ArchiveFilter = defineAsyncComponent(() => 
  import('./components/Archive/Filter.vue')
)

const MobileMenu = defineAsyncComponent(() => 
  import('./components/MobileMenu.vue')
)

const TestimonialCarousel = defineAsyncComponent(() => 
  import('./components/Pagebuilder/TestimonialCarousel.vue')
)

const TogglePassword = defineAsyncComponent(() => 
  import('./components/TogglePassword.vue')
);

const CommentEditor = defineAsyncComponent(() => 
  import('./components/CommentEditor.vue')
);

const VideoFavourites = defineAsyncComponent(() =>
  import('./components/Videos/Favourites.vue')
)

const VideoWatched = defineAsyncComponent(() =>
  import('./components/Videos/Watched.vue')
)

const PatreonChannels = defineAsyncComponent(() =>
  import('./components/Subscription/Patreon/Channels.vue')
)

const ArchiveVoucher = defineAsyncComponent(() =>
  import('./components/Archive/Voucher.vue')
)

const MultiSelectField = defineAsyncComponent(() =>
  import('./components/Community/FreeformMultiSelect.vue')
)

const CommunityMultiSelect = defineAsyncComponent(() =>
  import('./components/Community/FiltersMultiSelect.vue')
)



import './assets/main.css'

const app = createApp({
    components: {
        VideoPlayer,
        SubscriptionChannels,
        SubscriptionCancel,
        Accordion,
        Questionnaire,
        HelpAdviceHero,
        HelpAdviceFaqs,
        HelpAdviceVideos,
        VideoSearch,
        VideoLikes,
        HeaderMenuToggle,
        VideoModal,
        VideoGuidesModal,
        ArchiveFilter,
        MobileMenu,
        TestimonialCarousel,
        TogglePassword,
        VideoFavourites,
        CommentEditor,
        VideoWatched,
        PatreonChannels,
        ArchiveVoucher,
        MultiSelectField,
        CommunityMultiSelect
    }
});

app.use(createPinia())

app.use(apolloProvider)


app.mount('#app')